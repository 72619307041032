
  import { Component, Prop } from 'vue-property-decorator'
  import { Field } from './field'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'

@Component({})
  export default class ButtonGroup extends Field {
  @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>
  @Prop({ type: String }) readonly itemValue!: string
  @Prop({ type: String }) readonly itemText!: string

  get inputValue () {
    const { value, itemValue } = this
    return itemValue ? getObjectValueByPath(value, itemValue) : value
  }

  tooltip (value) {
    const { itemText } = this
    return itemText ? getObjectValueByPath(value, itemText) : value
  }
  }
